//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';

import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/plugins/eventBus';
import * as _ from 'underscore';

am4core.useTheme(am4themes_animated);
export default {
  name: 'flows',
  data() {
    return {
      showChart: true,
      includeDefault: true,
      showInnerLoading: false,
      response_data: [],
      Horizantal_Stacked_Access_Data: [],
      isCall: false,
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: "getDarkTheme"
    }),
    baseURl() {
      return window.location.href;
    },
  },
  methods: {
    ...mapActions({
      requestIntentStats: 'requestIntentStats',
    }),
    generateHorizantlyStackedChart() {
      const that = this;
       if (this.chart) {
        this.chart.dispose();
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark);
      }
      const interfaceColors = new am4core.InterfaceColorSet();
      const chart = am4core.create(that.$refs.horizontalStackAxes, am4charts.XYChart);

      chart.data = that.Horizantal_Stacked_Access_Data;
      // the following line makes value axes to be arranged vertically.
      chart.bottomAxesContainer.layout = 'horizontal';
      chart.bottomAxesContainer.reverseOrder = true;

      const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'name';
      categoryAxis.renderer.grid.template.stroke = interfaceColors.getFor('background');
      categoryAxis.renderer.grid.template.strokeOpacity = 1;
      categoryAxis.renderer.grid.template.location = 1;
      categoryAxis.renderer.minGridDistance = 20;

      const valueAxis1 = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis1.tooltip.disabled = true;
      valueAxis1.renderer.baseGrid.disabled = true;
      valueAxis1.marginRight = 30;
      valueAxis1.renderer.gridContainer.background.fill = interfaceColors.getFor('alternativeBackground');
      valueAxis1.renderer.gridContainer.background.fillOpacity = 0.05;
      valueAxis1.renderer.grid.template.stroke = interfaceColors.getFor('background');
      valueAxis1.renderer.grid.template.strokeOpacity = 1;
      valueAxis1.title.text = 'Total Occurrences';

      const series1 = chart.series.push(new am4charts.LineSeries());
      series1.dataFields.categoryY = 'name';
      series1.dataFields.valueX = 'occurance';
      series1.xAxis = valueAxis1;
      series1.name = 'Total Occurrences';
      const bullet1 = series1.bullets.push(new am4charts.CircleBullet());
      bullet1.tooltipText = '{valueX.value}';

      const valueAxis2 = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis2.tooltip.disabled = true;
      valueAxis2.renderer.baseGrid.disabled = true;
      valueAxis2.marginRight = 30;
      valueAxis2.renderer.gridContainer.background.fill = interfaceColors.getFor('alternativeBackground');
      valueAxis2.renderer.gridContainer.background.fillOpacity = 0.05;
      valueAxis2.renderer.grid.template.stroke = interfaceColors.getFor('background');
      valueAxis2.renderer.grid.template.strokeOpacity = 1;
      valueAxis2.title.text = 'Occurrence Percentage';

      const series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.dataFields.categoryY = 'name';
      series2.dataFields.valueX = 'occurancePercentage';
      series2.xAxis = valueAxis2;
      series2.name = 'Occurrence Percentage';
      const bullet2 = series2.bullets.push(new am4charts.CircleBullet());
      bullet2.fillOpacity = 0;
      bullet2.strokeOpacity = 0;
      bullet2.tooltipText = '{valueX.value}%';

      const valueAxis3 = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis3.tooltip.disabled = true;
      valueAxis3.renderer.baseGrid.disabled = true;
      valueAxis3.renderer.gridContainer.background.fill = interfaceColors.getFor('alternativeBackground');
      valueAxis3.renderer.gridContainer.background.fillOpacity = 0.05;
      valueAxis3.renderer.grid.template.stroke = interfaceColors.getFor('background');
      valueAxis3.renderer.grid.template.strokeOpacity = 1;
      valueAxis3.title.text = 'Confidence score';
      valueAxis3.max = 100;

      const series3 = chart.series.push(new am4charts.LineSeries());
      series3.dataFields.categoryY = 'name';
      series3.dataFields.valueX = 'confidanceScorePercentage';
      series3.xAxis = valueAxis3;
      series3.name = 'Confidence score %';
      const bullet3 = series3.bullets.push(new am4charts.CircleBullet());
      bullet3.tooltipText = '{valueX.value}%';

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.behavior = 'zoomY';

      const scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY = scrollbarY;
      var cellSize = 30;
      chart.events.on("datavalidated", function(ev) {

        // Get objects of interest
        var chart = ev.target;
        var categoryAxis = chart.yAxes.getIndex(0);

        // Calculate how we need to adjust chart height
        var adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

        // get current chart height
        var targetHeight = chart.pixelHeight + adjustHeight;

        // Set it on chart's container
        chart.svgContainer.htmlElement.style.height = targetHeight + "px";
      });

      this.chart = chart;
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
          from: 'onDateUpdate',
        };
        that.isCall = false;
        that.updateIntentDrilldownData(data);
      }
    },
    updateIntentDrilldownData(data) {
      const that = this;
      if (!that.isCall) {
        that.showInnerLoading = true;
        if (this.chart) {
          this.chart.dispose();
        }
        data.slotMins = 60;
        that.requestIntentStats(data).then((response) => {
          if (response.result.length > 0) {
            that.response_data = response.result;
            that.parseDataFormat();
          } else {
            that.showChart = false;
          }
        }).catch((err) => {
          that.showChart = false;
        }).finally(() => {
          that.showInnerLoading = false;
        });
      }
    },
    parseDataFormat() {
      const that = this;
      const data = that.response_data;
      const totalOccuranceArr = [];
      const intentFormattedArray = [];
      _.forEach(data, (obj) => {
        const intentObj = {
          name: obj.intent.toLowerCase(),
          occurance: 0,
        };
        const confidanceScoreArr = [];
        _.forEach(obj.valueList, (value) => {
          intentObj.occurance += value.occurance;
          confidanceScoreArr.push(value.confidanceScore);
          totalOccuranceArr.push(value.occurance);
        });
        intentObj.confidanceScore = Number((_.reduce(confidanceScoreArr, (memo, num) => memo + num, 0)).toFixed(2));
        intentFormattedArray.push(intentObj);
      });
      const totalOccurance = _.reduce(totalOccuranceArr, (memo, num) => memo + num, 0);
      const formatted_data = _.map(intentFormattedArray, (intenTObj) => {
        intenTObj.occurancePercentage = Number(((intenTObj.occurance / totalOccurance) * 100).toFixed(2));
        return intenTObj;
      });
      const percentage_data = _.map(formatted_data, (formattedObj) => {
        formattedObj.confidanceScorePercentage = Number(((formattedObj.confidanceScore / formattedObj.occurance) * 100).toFixed(2));
        return formattedObj;
      });
      if (that.includeDefault == true) {
        that.Horizantal_Stacked_Access_Data = _.sortBy(percentage_data, 'name').reverse();
      } else {
        const Horizantal_Stacked_Access_Data = [];
        _.forEach(percentage_data, (formObj) => {
          if (formObj.name !== 'default fallback.intent') {
            Horizantal_Stacked_Access_Data.push(formObj);
          }
        });
        that.Horizantal_Stacked_Access_Data = _.sortBy(Horizantal_Stacked_Access_Data, 'name').reverse();
      }
      setTimeout(() => {
        that.generateHorizantlyStackedChart();
        that.showInnerLoading = false;
      }, 300);
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'onAccountUpdate',
      };
      that.isCall = false;
      that.updateIntentDrilldownData(data);
    },
    isValid(value) {
      if (
        value === undefined
        || value === null
        || (typeof value === 'object' && Object.keys(value).length === 0)
        || (typeof value === 'string' && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    includeDefault() {
      this.parseDataFormat();
    },
    darkTheme(){
      this.generateHorizantlyStackedChart()
    }
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: 'mounted',
    };
    that.updateIntentDrilldownData(data);
  },
  created() {
    eventBus.$on('updateDate', this.onDateUpdate);
    eventBus.$on('updateAIAccount', this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
};
